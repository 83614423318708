.employee-list {
  margin: 20px;
}

.employee-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.employee-table th, .employee-table td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
}

.employee-table th {
  background-color: #f4f4f4;
}

.employee-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.delete-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: #ff1a1a;
}

.add-button {
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.add-button:hover {
  background-color: #45a049;
}
/* ボタンを横並びにする */
.employee-actions {
  display: flex;
  gap: 10px;
}

/* ボタンの基本スタイル */
.edit-button,
.delete-button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

/* 編集ボタンの色やスタイル */
.edit-button {
  background-color: #4CAF50; /* 緑色 */
  color: white;
}

/* 削除ボタンの色やスタイル */
.delete-button {
  background-color: #f44336; /* 赤色 */
  color: white;
}

/* ボタンのホバー効果 */
.edit-button:hover {
  background-color: #45a049;
}

.delete-button:hover {
  background-color: #d32f2f;
}
